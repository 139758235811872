<template>
  <div>
    <div id="paypal-button"></div>
    <v-alert class="mt-6" dismissible type="error" v-if="error">
      <span>
        Qualcosa è andata storto... Contatta @Ventu97 su Telegram o riprova
      </span>
    </v-alert>
  </div>
</template>

<script>
import api from "@/utils/api";
import PaymentHelper from "@/helper/payment";
export default {
  data() {
    return {
      error: false
    };
  },
  async mounted() {
    const that = this;
    const ph = PaymentHelper;
    await this.$loadScript(api.paypal.sdk_url()).then(() => {
      // eslint-disable-next-line
      paypal.Buttons({
          async createOrder() {
            that.error = false;
            const tier = that.$store.state.tesseramento.payment.tier;
            const key = [
              that.$store.state.tesseramento.player.nickname,
              that.$store.state.tesseramento.personali.email
            ].join("|");
            try {
              const req = await ph.create(tier, key);
              const order = req.data.orderID;
              return order;
            } catch {
              that.error = true;
              return false;
            }
          },
          async onApprove(data) {
            that.error = false;
            try {
              const key = [
                that.$store.state.tesseramento.player.nickname,
                that.$store.state.tesseramento.personali.email
              ].join("|");
              const req = await ph.check(data.orderID, key);
              return req.data;
            } catch {
              that.error = true;
            }
          }
        })
        .render("#paypal-button");
    });
  },
  destroyed() {
    this.$unloadScript(api.paypal.sdk_url());
  }
};
</script>
<style lang="scss">
#paypal-button {
  .paypal-buttons {
    margin: 0 auto;
    display: block;
  }
}
</style>
