<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="4"
        md="4"
        sm="4"
        xl="4"
        v-for="item in tiers"
        :key="item.tier"
      >
        <v-card
          :class="cardClass(item)"
          :style="cardStyle(item)"
          @click="changeTier(item)"
          :ref="`tier-${item.tier}`"
        >
          <v-card-title>
            <p class="center-title">{{ item.title }}</p>
          </v-card-title>
          <v-card-text class="card-body">
            <v-row>
              <v-col cols="12">
                <h1>{{ item.cost }} €</h1>
                <h5>+ {{ toTax(item.cost) }} € (Tasse di pagamento)</h5>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import theme from "@/plugins/vuetify-theme";
import api from "@/utils/api";
export default {
  data() {
    return {
      tiers: [
        { tier: 1, title: "Base", cost: 10 },
        { tier: 2, title: "Silver", cost: 25 },
        { tier: 3, title: "Gold", cost: 40 }
      ],
      api: api
    };
  },
  computed: {
    selectedTier: {
      get() {
        return this.$store.state.tesseramento.payment.tier;
      },
      set(v) {
        this.$store.state.tesseramento.payment.tier = v;
      }
    },
    paymentStatus: {
      get() {
        return this.$store.state.tesseramento.payment.paymentStatus;
      }
    },
    orderID: {
      get() {
        return this.$store.state.tesseramento.payment.orderId;
      }
    }
  },
  methods: {
    toTax(desiderato) {
      const paypalFee = -0.35;
      const percentuale = -3.4;
      let coeff_primo_membro = 0;
      let coeff_secondo_membro = 0;

      coeff_primo_membro = 1 + percentuale / 100;
      coeff_secondo_membro = desiderato - paypalFee;
      let result = coeff_secondo_membro / coeff_primo_membro;
      result = result - desiderato;
      return parseFloat(result).toFixed(2);
    },
    changeTier(item) {
      this.selectedTier = item.tier;
    },
    cardClass(item) {
      let css = ["card"];
      if (item.tier === this.selectedTier) {
        css.push("active");
      }
      return css;
    },
    cardStyle(item) {
      if (item.tier === this.selectedTier) {
        return {
          "background-color": theme.dark.primary
        };
      }
      return { "background-color": theme.dark.disabled };
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  user-select: none;
  cursor: pointer;
  .center-title {
    display: block;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .card-body {
    text-align: center;
  }
}
</style>
