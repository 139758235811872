let api = {
  base_api: "https://europe-west1-igpasd.cloudfunctions.net/tesseramento",
  payment: {
    api_base: function() {
      return `${api.base_api}/payment`;
    },
    create: function() {
      return `${this.api_base()}/create`;
    },
    check: function() {
      return `${this.api_base()}/check`;
    }
  },
  paypal: {
    clientId:
      "AXTr0XF4888U0xXCmrMq9peLRsvTiA2JXoo7Qoxay5YcLGaudZcxjGLL6QGeAVvWq1xdWePZ03M5_e6K",
    currency: "EUR",
    sdk_url: function() {
      const cid = this.clientId;
      const cur = this.currency;
      return `https://www.paypal.com/sdk/js?client-id=${cid}&currency=${cur}`;
    }
  },
  collector: {
    api_base: function() {
      return `${api.base_api}/collect`;
    },
    tesserato: function() {
      return `${this.api_base()}/tesserato`;
    }
  }
};
export default api;
