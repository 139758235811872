<template>
  <div class="home">
    <div v-if="paymentStatus !== 'COMPLETED'">
      <Tier />
      <div class="center">
        <p>
          Continuando con il Pagamento accetti i
          <a href="https://www.igpclan.it/privacy/" target="_blank">
            Termini di Servizio
          </a>
        </p>
      </div>
      <PayPal class="mt-4" />
    </div>
    <div v-else>
      <v-col cols="12" class="center">
        <p>Grazie per aver effettuato il Tesseramento</p>
        <p>Riceverai una mail di conferma all'indirrizzo email</p>
      </v-col>
    </div>
  </div>
</template>

<script>
import PayPal from "@/components/PayPal";
import Tier from "@/components/Forms/Tier";
export default {
  name: "Pagamento",
  components: {
    PayPal,
    Tier
  },
  computed: {
    paymentStatus: {
      get() {
        return this.$store.state.tesseramento.payment.paymentStatus;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.center {
  p {
    text-align: center;
  }
}
</style>
