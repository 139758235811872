import api from "@/utils/api";
import axios from "axios";
import store from "@/store";
class PaymentHelper {
  static create(tier, key) {
    const post = axios.post(api.payment.create(), {
      tier: tier,
      key: key
    });
    post.then(res => {
      const orderID = res.data.orderID;
      store.commit("UpdatePaymentOrder", orderID);
    });
    return post;
  }
  static check(order, key) {
    const post = axios.post(api.payment.check(), {
      order: order,
      key: key
    });
    post.then(res => {
      const Status = res.data.result.status;
      store.commit("UpdatePaymentStatus", Status);
    });
    return post;
  }
}

export default PaymentHelper;
